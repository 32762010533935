<template>
  <div>
    <div class="add_tag_view multiple_item_view_box">
      <h4 class="mr-2">
        Services Offered
      </h4>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-tooltip.hover.top="'Add new service offer'"
        variant="outline-primary"
        @click="addInput"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
      </b-button>
    </div>

    <div>
      <div class="row">
        <div
          v-for="(item, index) in service_offer"
          :key="index"
          class="col-md-12 mb-2"
        >
          <div class="row">
            <div :class="update ? 'col-md-9': 'col-md-10'">
              <b-form-input
                id=""
                v-model="item.name"
                type="text"
                placeholder="Service name"
              />
            </div>
            <div
              class="d-flex align-items-center"
              :class="update ? 'col-md-3': 'col-md-2'"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-b-tooltip.hover.top="'delete'"
                variant="outline-danger"
                class="mt-0"
                @click="removeInput(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
              </b-button>
              <b-button
                v-if="update === true"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-b-tooltip.hover.top="'Save'"
                variant="outline-success"
                class="ml-2"
                @click="updateInput(index)"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-25"
                />
              </b-button>
            </div>
          </div>
          <div class="">
            <!-- <hr> -->
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BButton, BFormSelect, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BButton,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  props: {
    update: { type: Boolean, default: false },
    id: { type: Number, default: null },
    category: { type: Number, default: null },
  },
  data() {
    return {
      user_type: '',
    }
  },
  computed: {
    profile_params() {
      return this.$store.state.businessProfile.admin_param
    },
    service_offer: {
      get() {
        return this.$store.state.serviceOffer.service_offer
      },
      set(value) {
        return value
      },
    },
    userData() {
      return this.$store.state.auth.user
    },
  },
  mounted() {
    this.user_type = localStorage.getItem('gts_user_type')
  },

  methods: {
    getBusinessProfile() {
      if (this.user_type === 'member') {
        this.$store.dispatch('businessProfile/getProfileVendor', this.profile_params)
      } else {
        this.$store.dispatch('businessProfile/getBusinessProfile', this.profile_params)
      }
    },
    addInput() {
      this.service_offer.push({
        name: '',
      })
    },
    removeInput(index) {
      const item = this.service_offer[index]
      if (item.id !== undefined) {
        this.$store.dispatch('serviceOffer/deleteSerivceOffer', item.id)
        const dom = this
        setTimeout(() => {
          dom.getBusinessProfile()
        }, 300)
      }
      this.service_offer.splice(index, 1)
    },
    updateInput(index) {
      const item = this.service_offer[index]
      const dom = this
      if (item.name !== '' && item.name !== '') {
        let data
        if (item.id === undefined || item.id === null) {
          item.category_id = this.category
          data = {
            business_profile_id: this.id,
            services: [item],
          }
          this.$store.dispatch('serviceOffer/addSerivceOffer', data)
          setTimeout(() => {
            dom.getBusinessProfile()
          }, 300)
        } else {
          data = item
          data.business_profile_id = this.id
          data.category_id = this.category
          this.$store.dispatch('serviceOffer/updateSerivceOffer', data)
          setTimeout(() => {
            dom.getBusinessProfile()
          }, 300)
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Fill the form fields before you can save',
            icon: 'AlertTriangleIcon',
            text: '',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
