<template>
  <b-card-text>
    <validation-observer ref="transactionForm">
      <b-form @submit.prevent="createTransaction">
        <b-row>
          <b-col
            cols="6"
            class="t-date"
          >
            <b-form-group
              label="Transaction ID"
              label-for="h-transaction-id"
              label-cols-md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="transaction_id"
                rules="required"
              >

                <b-form-input
                  id="v-transaction-id"
                  v-model="transaction.transaction_number"
                  :state="errors.length > 0 ? false:null"
                  placeholder="#### ## ##"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            class="t-date"
          >
            <b-form-group
              label="Transaction Date"
              label-for="h-transaction-date"
              label-cols-md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="transaction_date"
                rules="required"
              >
                <b-form-datepicker
                  id="h-transaction-date"
                  v-model="transaction.transaction_date"
                  class="mb-1"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
            <b-form-group
              label="Business"
              label-for="h-name"
              label-cols-md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="business"
                rules="required"
              >
                <v-select
                  v-model="business"
                  label="name"
                  :filterable="false"
                  :options="options"
                  :state="errors.length > 0 ? false:null"
                  @search="onSearch"
                >
                  <template slot="no-options">
                    type to search business
                  </template>
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <div class="d-center">
                      {{ option.name }}
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col
            cols="6"
            class="t-date"
          >
            <b-form-group
              label="Start Date"
              label-for="h-start-date"
              label-cols-md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-datepicker
                  id="h-start-date"
                  v-model="transaction.start_date"
                  class="mb-1"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="6"
            class="t-date"
          >
            <b-form-group
              label="End Date"
              label-for="h-end-date"
              label-cols-md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-datepicker
                  id="h-end-date"
                  v-model="transaction.end_date"
                  class="mb-1"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="mt-4 d-flex align-items-center justify-content-between">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            type="submit"
          >
            Save
          </b-button>
        </div>
      </b-form>

    </validation-observer>
  </b-card-text>
</template>

<script>
import {
  BButton, BCardText, BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    business: { type: Number, default: null },
    hide: { type: Function },
  },
  data() {
    return {
      transaction: {
        business_profile_id: null,
        transaction_number: null,
        transaction_date: null,
        start_date: null,
        end_date: null,
      },
      required,
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.profile
    },
  },
  methods: {
    createTransaction() {
      this.$refs.transactionForm.validate().then(success => {
        if (success) {
          const data = this.transaction
          data.business_profile_id = this.business
          data.staff_id = this.userData.id
          this.$store.dispatch('transaction/createTransaction', data)
          this.hide()
          this.emptyData()
          const dom = this
          setTimeout(() => {
            dom.$store.dispatch('transaction/getTransactionByBusinessProfile', this.business)
          }, 300)
        }
      })
    },
    emptyData() {
      const dom = this
      setTimeout(() => {
        dom.transaction = {
          transaction_number: null,
          business_profile_id: null,
          transaction_date: null,
          start_date: null,
          end_date: null,
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
