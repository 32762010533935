<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Business Statistics</h4>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button> -->
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Search by keyword"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="search here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="vendor_statistics"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @row-clicked="showDetails"
      >
        <!-- <template #cell(cover)="data">
          <b-avatar
            :src="getImage(data.value)"
            variant="light-success"
          />
        </template> -->
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="per_page"
        size="lg"
        class="mt-3 pagination-success"
        align="center"
        @change="onPageChange"
      />
    </b-card>

    <BusinessProfileDetails />
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BAvatar, BPagination,
  BFormInput,
  BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BusinessProfileDetails from './components/BusinessProfileDetails.vue'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BAvatar,
    BPagination,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BusinessProfileDetails,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      fields: [{ key: 'id', label: '#ID' }, { key: 'name', label: 'Business', sortable: true }, { key: 'views', label: 'Views', sortable: true }, { key: 'recommendations', label: 'Recommendations', sortable: true }, { key: 'reviews', label: 'reviews', sortable: true }, { key: 'messages', label: 'messages', sortable: true }],
      currentPage: 1,
      per_page: 10,
      sortBy: 'id',
      sortDesc: false,
      searchTerm: '',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    vendor_statistics() {
      return this.$store.state.statistic.vendor_statistics
    },
    rows() {
      return this.$store.state.statistic.total
    },
  },
  watch: {
    '$store.state.businessProfile.admin_business_profiles': {
      handler() {
        const dom = this
        setTimeout(() => {
          dom.getVendorStatistics()
        }, 200)
      },
    },
  },
  mounted() {
    this.getVendorStatistics()
  },
  methods: {
    getVendorStatistics() {
      return this.$store.dispatch('statistic/getVendorStatistics', { page: 1, per_page: this.per_page })
    },
    onPageChange(page) {
      this.$store.dispatch('statistic/getVendorStatistics', { page, per_page: this.per_page })
    },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
    },
    showDetails(item) {
      this.$store.dispatch('businessProfile/getSingleProfileDetails', item.id)
    },
  },
}
</script>

<style>

</style>
