<template>
  <div>
    <div
      v-if="thumb === true"
      class="row mb-1"
    >
      <div
        v-if="profile_cover_photo"
        class="col-md-3 mb-2"
      >
        <div
          v-lazy:background-image="getImage(profile_cover_photo)"
          class="image__gallery__card"
        />
      </div>
      <div class="col-sm-12 col-md-12 mb-2">
        <h5>Cover image:</h5>
        <VueFileAgent
          ref="coverFileAgent"
          v-model="fileRecords"
          :theme="'list'"
          :multiple="false"
          :deletable="true"
          :meta="true"
          accept="image/*"
          :max-size="'10MB'"
          :max-files="14"
          :help-text="'Choose document'"
          @select="filesSelected($event)"
          @beforedelete="onBeforeDelete($event)"
          @delete="fileDeleted($event)"
        />
        <b-button
          v-if="update"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-success"
          class="mt-2 mb-2"
          @click="addCoverPhoto"
        >
          Save Cover Photo
        </b-button>
      </div>

    </div>
    <hr>

    <div
      v-if="thumb !== true"
      class="row mt-3"
    >
      <div
        v-for="(item, index) in gallery"
        :key="index"
        class="col-md-3 mb-2"
      >
        <div
          v-lazy:background-image="getImage(item.path)"
          class="image__gallery__card"
        >
          <div
            class="close_icon"
            @click="removeInput(index)"
          >
            <feather-icon
              icon="TrashIcon"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="thumb !== true"
      class="row"
    >
      <div class="col-sm-12 col-md-12 mb-3 mt-2">
        <h5>Gallery Images: (Choose multiple images or video)</h5>
        <VueFileAgent
          ref="galleryFileAgent"
          v-model="fileGalleryRecords"
          :theme="'list'"
          :multiple="true"
          :deletable="true"
          :meta="true"
          accept="image/*,video/*"
          :max-size="'200MB'"
          :max-files="14"
          :help-text="'Choose document'"
          :error-text="errorText"
          @select="filesGallerySelected($event)"
          @beforedelete="onGalleryBeforeDelete($event)"
          @delete="fileGalleryDeleted($event)"
        />
        <b-button
          v-if="update"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-success"
          class="mt-2 mb-2"
          @click="addGallery"
        >
          Save Gallery
        </b-button>
      </div>

    </div>
  </div>
</template>

<script>
import { BFormInput, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file.file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  components: {
    BFormInput, BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    gallery: { type: Array },
    cover: { type: String },
    id: { type: Number },
    update: { type: Boolean, default: false },
    thumb: { type: Boolean, default: false },
    hideEdited: { type: Function },
  },
  data() {
    return {
      fileRecords: [],
      uploadUrl: 'https://www.mocky.io/v2/5d4fb20b3000005c111099e3',
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [], // maintain an upload queue
      fileGalleryRecords: [],
      uploadGalleryUrl: 'https://www.mocky.io/v2/5d4fb20b3000005c111099e3',
      uploadGalleryHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileGallryRecordsForUpload: [], // maintain an upload queue
      errorText: {
        type: 'Invalid file type. Only images Allowed',
        size: 'Files should not exceed 10MB in size',
      },
      user_type: '',
    }
  },
  computed: {
    profile_params() {
      return this.$store.state.businessProfile.admin_param
    },
    gallery_items: {
      get() {
        return this.$store.state.businessProfile.gallery_items
      },
      set(value) {
        return value
      },
    },
    cover_photo: {
      get() {
        return this.$store.state.businessProfile.cover_photo
      },
      set(value) {
        return value
      },
    },
    userData() {
      return this.$store.state.auth.user
    },
    profile_cover_photo() {
      return this.$store.state.businessProfile.profile_cover_photo
    },

  },
  mounted() {
    this.user_type = localStorage.getItem('gts_user_type')
  },
  methods: {
    uploadFiles() {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.coverFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload,
      )
      this.fileRecordsForUpload = []
    },
    deleteUploadedFile(fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.coverFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord,
      )
    },
    getBusinessProfile() {
      if (this.user_type === 'member') {
        this.$store.dispatch('businessProfile/getProfileVendor', this.profile_params)
      } else {
        this.$store.dispatch('businessProfile/getBusinessProfile', this.profile_params)
      }
    },
    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(
        fileRecord => !fileRecord.error,
      )
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords,
      )

      const file = this.fileRecordsForUpload
      const dom = this
      // eslint-disable-next-line func-names
      return (async function (file) {
        const data = await toBase64(file[0])
        dom.cover_photo.name = file[0].file.name
        dom.cover_photo.file = data
      }(file))
    },
    onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1)
        const k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) this.fileRecords.splice(k, 1)
      } else if (confirm('Are you sure you want to delete?')) {
        this.$refs.coverFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },

    uploadGalleryFiles() {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.galleryFileAgent.upload(
        this.uploadGalleryUrl,
        this.uploadGalleryHeaders,
        this.fileGallryRecordsForUpload,
      )
      this.fileGallryRecordsForUpload = []
    },
    deleteGalleryUploadedFile(fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.galleryFileAgent.deleteUpload(
        this.uploadGalleryUrl,
        this.uploadGalleryHeaders,
        fileRecord,
      )
    },
    filesGallerySelected(fileRecordsNewlySelected) {
      this.$store.dispatch('businessProfile/emptyGallery', [])
      const validGalleryFileRecords = fileRecordsNewlySelected.filter(
        fileRecord => !fileRecord.error,
      )
      this.fileGallryRecordsForUpload = this.fileGallryRecordsForUpload.concat(
        validGalleryFileRecords,
      )
      const files = this.fileGallryRecordsForUpload
      files.forEach(async file => {
        const data = await toBase64(file)
        if (file.ext === 'jpg' || file.ext === 'jpeg' || file.ext === 'png' || file.ext === 'svg' || file.ext === 'gif' || file.ext === 'webp') {
          const item = [{ is_video: false, name: file.file.name, file: data }]
          this.$store.dispatch('businessProfile/getGalleries', item)
        } else {
          const item = [{ is_video: true, name: file.file.name, file: data }]
          this.$store.dispatch('businessProfile/getGalleries', item)
        }
      })
    },
    onGalleryBeforeDelete(fileRecord) {
      const i = this.fileGallryRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileGallryRecordsForUpload.splice(i, 1)
        const k = this.fileGalleryRecords.indexOf(fileRecord)
        if (k !== -1) this.fileGalleryRecords.splice(k, 1)
      } else if (confirm('Are you sure you want to delete?')) {
        this.$refs.galleryFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },
    fileGalleryDeleted(fileRecord) {
      const i = this.fileGallryRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileGallryRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
    // getImage(image) {
    //   if (image !== null || image !== '') {
    //     return `background: url(${process.env.VUE_APP_STORAGE_PATH + image})`
    //   }
    //   return image
    // },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    addGallery() {
      const data = {
        business_profile_id: this.id,
        gallery_items: this.gallery_items,
      }
      this.$store.dispatch('businessProfile/addGallery', data)
      const dom = this
      setTimeout(() => {
        dom.getBusinessProfile()
      }, 300)
      this.$refs.galleryFileAgent.deleteFileRecord(this.fileGalleryRecords)
      // this.hideEdited()
      this.fileGalleryRecords = []
      this.fileGallryRecordsForUpload = []
    },
    async addCoverPhoto() {
      const data = {
        business_profile_id: this.id,
        cover: this.cover_photo.file,
      }
      await this.$store.dispatch('businessProfile/updateProfileCoverPhoto', data)

      const dom = this
      dom.$refs.coverFileAgent.deleteFileRecord(this.fileRecords)
      setTimeout(() => {
        dom.getBusinessProfile()
      }, 3000)
    },
    removeInput(index) {
      const item = this.gallery[index]
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (item.id !== undefined) {
            this.$store.dispatch('businessProfile/deleteGallery', item.id)
            this.getBusinessProfile()
          }
          this.gallery.splice(index, 1)
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
