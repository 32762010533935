<template>
  <b-row class="mb-4">
    <b-col cols="12">
      <b-row>
        <b-col
          cols="12"
          class=""
        >

          <b-form-group
            label="Street Address"
            label-for="h-address"
            label-cols-md="12"
          >
            <b-form-input
              id="h-address"
              v-model="address.address1"
              placeholder="Address"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>

    <!-- <b-col cols="6">
      <b-form-group
        label="Address 2"
        label-for="h-address-2"
        label-cols-md="2"
      >
        <b-form-input
          id="h-address-2"
          placeholder="Address 2"
        />
      </b-form-group>
    </b-col> -->
    <b-col cols="12">
      <b-row>
        <b-col
          cols="5"
          class=""
        >

          <b-form-group
            label="City"
            label-for="h-city"
            label-cols-md="12"
          >
            <b-form-input
              id="h-city"
              v-model="address.city"
              placeholder="City"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="5"
          class=""
        >
          <b-form-group
            label="State"
            label-for="h-state"
            label-cols-md="12"
          >

            <b-form-select
              id="h-category"
              v-model="address.state_id"
              :options="state_options"
              @change="filterState"
            />

          </b-form-group>
        </b-col>
        <b-col
          cols="2"
          class=""
        >

          <b-form-group
            label="Zip Code"
            label-for="h-zip-code"
            label-cols-md="12"
          >
            <b-form-input
              id="h-zip-code"
              v-model="address.zip_code"
              placeholder="zip-code"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="4">
      <b-form-group
        label="County"
        label-for="h-county"
        label-cols-md="12"
      >
        <b-form-select
          id="h-county"
          v-model="address.county_id"
          :options="county_options"
          @change="filterState"
        />

      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  computed: {
    address: {
      get() {
        return this.$store.state.businessProfile.address
      },
      set(value) {
        return value
      },
    },
    states() {
      return this.$store.state.state.states
    },
    counties() {
      return this.$store.state.county.counties
    },
    state_options() {
      return this.states.map(item => {
        const data = { value: item.id, text: `${item.name}` }
        return data
      })
    },
    county_options() {
      return this.counties.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
  },
  mounted() {
    this.getState()
    this.getCounty()
  },
  methods: {
    getState() {
      return this.$store.dispatch('state/getStates')
    },
    getCounty() {
      return this.$store.dispatch('county/getCounties', this.address.state_id)
    },
    filterState() {
      return this.$store.dispatch('county/getCounties', this.address.state_id)
    },
  },
}
</script>

<style scoped>

</style>
