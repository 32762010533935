<template>
  <div>
    <div class="add_tag_view multiple_item_view_box">
      <h4 class="mr-2">
        Business Hours
      </h4>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-tooltip.hover.top="'Add new business hours'"
        variant="outline-primary"
        @click="addInput"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
      </b-button>
    </div>

    <div>
      <div class="row">
        <div
          v-for="(item, index) in hours"
          :key="index"
          class="col-md-12 mb-2"
        >
          <div class="row">
            <div :class="update ? 'col-md-3' : 'col-md-4'">
              <b-form-group
                label="Select Days"
                label-for="h-member"
                label-cols-md="12"
              >
                <b-form-select
                  id="h-days"
                  v-model="item.day_id"
                  :options="day_options"
                />
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group
                label="Open At"
                label-for="h-hours-start"
                label-cols-md="12"
              >
                <b-form-input
                  id="hours-start"
                  v-model="item.business_hours_start"
                  type="time"
                  placeholder="website"
                />
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group
                label="Close At"
                label-for="hours-end"
                label-cols-md="12"
              >
                <b-form-input
                  id="hours-end"
                  v-model="item.business_hours_end"
                  type="time"
                  placeholder=""
                />
              </b-form-group>
            </div>
            <div
              class="d-flex align-items-center"
              :class="update ? 'col-md-3' : 'col-md-2'"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-b-tooltip.hover.top="'delete'"
                variant="outline-danger"
                class=""
                style="margin-top: 20px"
                @click="removeInput(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
              </b-button>
              <b-button
                v-if="update === true"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-success"
                v-b-tooltip.hover.top="'Save'"
                class="ml-2"
                style="margin-top: 20px"
                @click="updateInput(index)"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-25"
                />
              </b-button>

            </div>
          </div>
          <!-- <div class="col-7">
            <hr>
          </div> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BButton, BFormSelect, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BButton,
    BFormSelect,
    BFormGroup,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  props: {
    update: { type: Boolean, default: false },
    id: { type: Number, default: null },
  },
  data() {
    return {
      user_type: "",
    }
  },
  computed: {
    hours: {
      get() {
        return this.$store.state.workingHours.hours
      },
      set(value) {
        return value
      },
    },
    days() {
      return this.$store.state.businessProfile.days
    },
    day_options() {
      return this.days.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    profile_params() {
      return this.$store.state.businessProfile.admin_param
    },
    userData() {
      return this.$store.state.auth.user
    },
  },
  mounted() {
    this.user_type = localStorage.getItem('gts_user_type')
  },
  methods: {
    getBusinessProfile() {
      if (this.user_type === 'member') {
        this.$store.dispatch('businessProfile/getProfileVendor', this.profile_params)
      } else {
        this.$store.dispatch('businessProfile/getBusinessProfile', this.profile_params)
      }
    },
    addInput() {
      this.hours.push({
        day_id: null,
        business_hours_start: '',
        business_hours_end: '',
      })
    },
    removeInput(index) {
      const item = this.hours[index]
      const dom = this
      if (item.id !== undefined) {
        this.$store.dispatch('workingHours/deleteWorkingHours', item.id)
        setTimeout(() => {
          dom.getBusinessProfile()
        }, 300)
      }
      this.hours.splice(index, 1)
    },
    updateInput(index) {
      const item = this.hours[index]
      const dom = this
      if (item.day_id !== null && item.business_hours_start !== '' && item.business_hours_end !== '') {
        let data

        if (item.id === undefined || item.id === null) {
          data = {
            business_profile_id: this.id,
            business_hours: [item],
          }
          this.$store.dispatch('workingHours/addWorkingHours', data)
          setTimeout(() => {
            dom.getBusinessProfile()
          }, 300)
        } else {
          data = item
          data.business_profile_id = this.id
          this.$store.dispatch('workingHours/updateWorkingHours', data)
          setTimeout(() => {
            dom.getBusinessProfile()
          }, 300)
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Fill the form fields before you can save',
            icon: 'AlertTriangleIcon',
            text: '',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
