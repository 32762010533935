<template>
  <div>
    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate Profile' : 'Add New Profile'"
      hide-footer
      size="huge"
      @hide="hide"
    >
      <b-card-text>
        <div class="bp_form_box">
          <div class="bp_form_side_bar">
            <div
              v-for="(data, index) in tab_items"
              :key="index"
              class="bp_tab_link"
              :class="is_tab_active === data.id ? 'bp_active' : ''"
              @click="showFormContent(data.id)"
            >
              <div class="bp_tab_counter">
                {{ index + 1 }}
              </div>
              <div class="bp_text_view">
                <span> {{ data.title }} </span>
              </div>
            </div>
          </div>
          <div class="bp_form_view">
            <div class="form_content">
              <validation-observer ref="profileForm">
                <b-form>
                  <b-row v-if="is_tab_active === 1">
                    <b-col
                      cols="12"
                      class="mt-2"
                    >

                      <span>Members</span>
                      <b-form-group
                        label=""
                        label-for="h-member"
                        label-cols-md="12"
                      >
                        <b-form-select
                          id="h-member"
                          v-model="profile.member_id"
                          :options="user_options"
                        />
                      </b-form-group>
                    </b-col>
                    <hr></hr>
                    <b-col cols="6">
                      <b-form-group
                        label="Business Name"
                        label-for="h-name"
                        label-cols-md="12"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="name"
                          rules="required"
                        >
                          <b-form-input
                            id="h-name"
                            v-model="profile.name"
                            type="text"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="6">
                      <b-form-group
                        label="Category "
                        label-for="h-categories"
                        label-cols-md="12"
                      >
                        <b-form-select
                          id="h-categories"
                          v-model="profile.category_id"
                          :options="categories_options"
                          @change="changeCategory"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="6">
                      <b-form-group
                        label="Business Email"
                        label-for="h-email"
                        label-cols-md="12"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="email"
                          rules="required|email"
                        >
                          <b-form-input
                            id="h-email"
                            v-model="profile.email"
                            type="email"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col cols="6">

                      <b-form-group
                        label="Sub Categories "
                        label-for="h-sub-categories"
                        label-cols-md="12"
                      >
                        <b-form-select
                          id="h-sub-categories"
                          v-model="profile.sub_category_id"
                          :options="sub_categories_options"
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col cols="6">
                      <b-form-group
                        label="Business Phone"
                        label-for="h-phone"
                        label-cols-md="12"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="phone"
                          rules="required"
                        >
                          <b-form-input
                            id="h-phone"
                            v-model="profile.phone"
                            type="text"
                            :state="errors.length > 0 ? false:null"
                            placeholder="phone"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col cols="6">
                      <b-form-group
                        label="License"
                        label-for="h-license"
                        label-cols-md="12"
                      >
                        <b-form-checkbox
                          :checked="has_license ? true : false"
                          name="check-button"
                          switch
                          inline
                          :class="has_license ? 'mb-2' : ''"
                          @change="changeHasLicense"
                        >
                          has license
                        </b-form-checkbox>
                        <b-form-input
                          v-if="has_license"
                          id="h-license"
                          v-model="profile.license"
                          placeholder="license"
                        />
                      </b-form-group> -->
                    <!-- </b-col> -->
                    <!-- <b-col cols="6">
                      <b-form-group
                        label="Tags"
                        label-for="h-tags"
                        label-cols-md="12"
                      >
                        <MultipleSelect
                          :options="tag_options"
                          :select-options="selectedTags"
                          :remove="removeTags"
                          :selected="selectTags"
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col cols="6">
                      <b-form-group
                        label="Business Website"
                        label-for="h-website"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-website"
                          v-model="profile.website"
                          placeholder="website"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Name"
                        label-for="h-contact_name"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_name"
                          v-model="profile.contact_name"
                          placeholder="Contact person title"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Title"
                        label-for="h-contact_person_title"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_person_title"
                          v-model="profile.contact_title"
                          placeholder="Contact person title"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Phone"
                        label-for="h-contact_person_phone"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_person_phone"
                          v-model="profile.contact_phone"
                          placeholder="Contact person phone"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Email"
                        label-for="h-contact_person_email"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_person_email"
                          v-model="profile.contact_email"
                          placeholder="Contact person email"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                      <h5>Active State(s)</h5>
                      <div class="state_tag_view banner_service__offter">
                        <b-badge
                          v-for="(item, index) in profile.authorized_states"
                          :key="index"
                          class="mr-1 mb-1"
                          icon="MapPinIcon"
                          variant="secondary"
                        >
                          <feather-icon
                            icon="MapPinIcon"
                            class="mr-25"
                          /> {{ item.state.name }}
                        </b-badge>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row v-if="is_tab_active === 2">
                    <!-- <b-col
                      cols="12"
                      class=""
                    >
                      <b-form-group
                        label="Find location"
                        label-for="h-latitude"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-latitude"
                          v-model="map_address"
                          placeholder="Enter Address to find location"
                        /></b-form-group>
                    </b-col>

                    <b-col
                      cols="6"
                      class=""
                    >
                      <b-form-group
                        label="Latitude"
                        label-for="h-latitude"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-latitude"
                          v-model="profile.lat"
                          placeholder="Enter Latitude"
                        /></b-form-group>
                    </b-col>

                    <b-col
                      cols="6"
                      class=""
                    >
                      <b-form-group
                        label="Logitude"
                        label-for="h-longitude"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-longitude"
                          v-model="profile.lng"
                          placeholder="Enter Longitude"
                        /></b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-success"
                        class="mt-2 mb-2"
                        block
                        @click="findCords"
                      >
                        Find Location
                      </b-button>
                    </b-col> -->

                    <b-col
                      cols="12"
                      class="mt-4"
                    >
                      <ServiceArea
                        :id="profile.id"
                        :update="is_update"
                        :category="profile.category_id"
                        :authorized-states="profile.authorized_states"
                      />
                    </b-col>
                    <!-- <b-col cols="12">
                      <div
                        v-if="is_tab_active === 2"
                        ref="mapContainer"
                        style="height: 380px; width: 100%"
                      />
                    </b-col> -->

                  </b-row>

                  <b-row v-if="is_tab_active === 3">
                    <b-col cols="12">
                      <ServiceOffer
                        :id="profile.id"
                        :update="is_update"
                        :category="profile.category_id"
                      />
                    </b-col>
                  </b-row>

                  <b-row v-if="is_tab_active === 4">
                    <b-col cols="12">
                      <quill-editor
                        v-model="profile.description"
                        style="height: 500px !important; min-height: 500px !important; margin-bottom: 100px !important"
                      />
                    </b-col>
                  </b-row>

                  <b-row v-if="is_tab_active === 5">
                    <b-col cols="12">
                      <WorkingHours
                        :id="profile.id"
                        :update="is_update"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 6">
                    <b-col cols="12">
                      <License
                        :id="profile.id"
                        :update="is_update"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 7">
                    <b-col cols="12">
                      <Address />
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 8">
                    <b-col cols="12">
                      <Gallery
                        :id="profile.id"
                        :cover="profile.cover"
                        :gallery="profile.gallery"
                        :update="is_update"
                        :thumb="true"
                      />
                    </b-col>
                    <b-col cols="12">
                      <Gallery
                        :id="profile.id"
                        :gallery="profile.gallery"
                        :update="is_update"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 9 && is_update">
                    <b-col
                      v-if="profile_reviews.length < 1"
                      cols="12"
                    >
                      <div

                        class="sharemain_box"
                      >
                        <div
                          v-b-tooltip.hover.top="'Share link to this business'"
                          class="share_main_icon_box"
                        >
                          <feather-icon
                            icon="MessageSquareIcon"
                            size="60"
                          />
                        </div>
                        <h4>Review for this business: {{ profile_reviews.length }}</h4>
                      </div>
                    </b-col>
                    <b-col
                      v-else
                      cols="12"
                    >
                      <div
                        v-for="(review, index) in profile_reviews"
                        :key="index"
                        class="business_reviw__card"
                      >
                        <div class="reviewer_view">
                          <b-avatar
                            v-if="review.reviewer.avatar === null || review.reviewer.avatar === ''"
                            variant="success"
                            :text="getUserText(review.reviewer.first_name, review.reviewer.last_name)"
                            size="50"
                          />
                          <b-avatar
                            v-else
                            :src="getImage(review.reviewer.avatar)"
                            size="50"
                          />
                          <div class="review-heading_box">
                            <div class="heading">
                              <h4>{{ review.reviewer.first_name }} {{ review.reviewer.last_name }}, {{ review.reviewer.profession }}</h4>
                              <p style="margin-top: -10px;"> <b-form-rating
                                variant="warning"
                                no-border
                                :value="review.rating"
                                readonly
                                inline
                                class="raiting_box"
                                style="border:none !important; padding-left: 0 !important;"
                              /></p>
                            </div>
                            <div>    <b-badge
                              :variant="review.status === 'active' ? 'light-success':
                                review.status === 'reported' ? 'light-danger' : review.status === 'under review' ? 'light-warning' : 'light-secondary'
                              "
                            >
                              {{ review.status }}
                            </b-badge></div>

                          </div>

                        </div>

                        <p>{{ review.review }}</p>
                        <hr>
                        <div class="row">
                          <div class="col-md-4">
                            <h5>Customer Service</h5>
                            <span>{{ review.content.customer_service }}</span>
                          </div>

                          <div class="col-md-4">
                            <h5>Pricing</h5>
                            <span>{{ review.content.pricing }}</span>
                          </div>

                          <div class="col-md-4">
                            <h5>Quality</h5>
                            <span>{{ review.content.quality }}</span>
                          </div>

                          <div class="col-md-4 mt-2">
                            <h5>Timeliness</h5>
                            <span>{{ review.content.timeliness }}</span>
                          </div>

                          <div class="col-md-4 mt-2">
                            <h5>Recommendation</h5>
                            <span>{{ review.content.recommend }}</span>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 11 && is_update">
                    <b-col cols="12">
                      <div class="d-flex align-items-center justify-content-between mb-2">
                        <h4>System Status</h4>
                        <b-badge
                          :variant="system_status.status === 'active' ? 'light-success':
                            system_status.status === 'cancel' ? 'light-danger' : system_status.status === 'waiting for review' ? 'light-warning' : 'light-secondary'
                          "
                          style="text-transform: capitalize !important;"
                        >
                          {{ system_status.status }}
                        </b-badge>

                      </div>
                      <b-alert
                        v-if="system_status.reason.length > 0"
                        variant="info"
                        show
                      >
                        <h4 class="alert-heading">
                          Reason
                        </h4>
                        <div class="alert-body">
                          {{ system_status.reason }}
                        </div>
                      </b-alert>
                      <b-button
                        v-if="is_update"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"

                        @click="openSystemStatus"
                      >
                        Set System Status
                      </b-button>
                      <b-modal
                        id="system-status-modal"
                        title="Change System Status"
                        hide-footer
                      >
                        <b-card-text>

                          <validation-observer ref="systemStatusForm">
                            <b-form @submit.prevent="changeSystemStatus">
                              <b-row>
                                <!-- first name -->
                                <b-col cols="12">
                                  <b-form-group
                                    label="Status"
                                    label-for="v-status"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="status"
                                      rules="required"
                                    >
                                      <b-form-select
                                        id="v-status"
                                        v-model="system_status.status"
                                        :options="system_options"
                                        class="mb-1"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>

                                  <b-form-group
                                    label="Enter the reason why you want to change status."
                                    label-for="v-reason"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="reason"
                                      rules="required"
                                    >
                                      <b-form-textarea
                                        id="v-reason"
                                        v-model="system_status.reason"
                                        placeholder=""
                                        rows="5"
                                        :state="errors.length > 0 ? false:null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <div

                                class="button_view d-flex align-items-center justify-content-between"
                              >
                                <div />

                                <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="outline-success"
                                  type="submit"
                                >
                                  Change status
                                </b-button>
                              </div>
                            </b-form>
                          </validation-observer>
                        </b-card-text>

                      </b-modal>

                      <hr>
                      <div class="d-flex align-items-center justify-content-between mt-4 mb-2">
                        <h4>Application Status</h4>
                        <b-badge
                          :variant="application_status.status === 'active' ? 'light-success':
                            application_status.status === 'cancel' ? 'light-danger' : application_status.status === 'waiting for review' ? 'light-warning' : 'light-secondary'
                          "
                          style="text-transform: capitalize !important;"
                        >
                          {{ application_status.status }}
                        </b-badge>

                      </div>
                      <b-alert
                        v-if="application_status.reason.length > 0"
                        variant="warning"
                        show
                      >
                        <h4 class="alert-heading">
                          Reason
                        </h4>
                        <div class="alert-body">
                          {{ application_status.reason }}
                        </div>
                      </b-alert>

                      <b-button
                        v-if="is_update"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"

                        @click="openApplicationStatus"
                      >
                        Set Application Status
                      </b-button>
                      <b-modal
                        id="application-status-modal"
                        title="Change Application Status"
                        hide-footer
                      >
                        <b-card-text>

                          <validation-observer ref="applicationStatusForm">
                            <b-form @submit.prevent="changeApplicationStatus">
                              <b-row>
                                <!-- first name -->
                                <b-col cols="12">
                                  <b-form-group
                                    label="Status"
                                    label-for="v-status"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="status"
                                      rules="required"
                                    >
                                      <b-form-select
                                        id="v-status"
                                        v-model="application_status.status"
                                        :options="application_options"
                                        class="mb-1"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>

                                  <b-form-group
                                    label="Enter the reason why you want to change status."
                                    label-for="v-reason"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="reason"
                                      rules="required"
                                    >
                                      <b-form-textarea
                                        id="v-reason"
                                        v-model="application_status.reason"
                                        placeholder=""
                                        rows="5"
                                        :state="errors.length > 0 ? false:null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <div

                                class="button_view d-flex align-items-center justify-content-between"
                              >
                                <div />

                                <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="outline-success"
                                  type="submit"
                                >
                                  Change status
                                </b-button>
                              </div>
                            </b-form>
                          </validation-observer>
                        </b-card-text>

                      </b-modal>
                    </b-col>
                  </b-row>

                  <b-row v-if="is_tab_active === 10 && is_update">
                    <b-col
                      v-if="transactions.length < 1"
                      cols="12"
                    >
                      <div

                        class="sharemain_box"
                      >
                        <div
                          v-b-tooltip.hover.top="'Add transaction to this business'"
                          class="share_main_icon_box"
                          @click="openTransactionModel"
                        >
                          <feather-icon
                            icon="ClipboardIcon"
                            size="60"
                          />
                        </div>
                        <h4>Transaction for this business: {{ transactions.length }}</h4>
                      </div>

                      <b-modal
                        id="transaction-modal"
                        title="Add transaction"
                        hide-footer
                        size="lg"
                      >
                        <TransactionItem
                          :business="profile.id"
                          :hide="hideTransaction"
                        />
                      </b-modal>
                    </b-col>
                    <b-col
                      v-else
                      cols="12"
                    >
                      <!-- {{ transactions }} -->
                      <b-row>
                        <b-col
                          v-for="(transaction, index) in transactions"
                          :key="index"
                          cols="12"
                        >
                          <div class="transaction_list_card mb-2">
                            <div class="heading-text-view">
                              <div class="staff-item-box">
                                <b-avatar
                                  v-if="transaction.staff.avatar === null || transaction.staff.avatar === ''"
                                  :text="getUserText(transaction.staff.first_name, transaction.staff.last_name)"
                                  variant="success"
                                />
                                <b-avatar
                                  v-else
                                  :src="getImage(transaction.staff.avatar)"
                                  variant="light-success"
                                />

                                <div class="created_By_box">
                                  <h5>Created By</h5>
                                  <span class="user_name">{{ transaction.staff && transaction.staff.first_name }} {{ transaction.staff && transaction.staff.last_name }}</span>
                                </div>
                              </div>
                              <div>
                                <h5>Created At</h5>
                                <span class="date-text">{{ date(transaction.transaction_date) }}</span>
                              </div>

                            </div>

                            <hr>
                            <b-alert
                              variant="secondary"
                              show
                            >
                              <h5 class="alert-heading">
                                Transaction Number
                              </h5>
                              <div class="alert-body">
                                <span>{{ transaction.transaction_number }}</span>
                              </div>

                            </b-alert>
                            <hr>

                            <div class="date-heading-view">
                              <div>
                                <h5>Start Date:</h5>
                                <span>{{ date(transaction.start_date) }}</span>
                              </div>
                              <feather-icon icon="GitCommitIcon" />
                              <div>
                                <h5>End Date:</h5>
                                <span>{{ date(transaction.end_date) }}</span>
                              </div>
                            </div>

                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </div>
            <div
              v-if="is_created || is_update && is_tab_active === 1 || is_update && is_tab_active === 4 || is_update && is_tab_active === 7"
              class="button_view d-flex align-items-center justify-content-between"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
              <!-- <b-button
                v-if="is_update && is_tab_active === 1"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"

                @click="setAsInactiveData"
              >
                Set as Inactive
              </b-button> -->

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                :disabled="is_edited === false && is_update ? true : false"
                @click="is_update ? updateBusinessProfile() : createBusinessProfile()"
              >
                Save Changes
              </b-button>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal, VBModal, BCardText,
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormSelect, BFormCheckbox,
  BAvatar, BFormRating, BBadge, BFormTextarea, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import WorkingHours from './WorkingHours.vue'
import License from './License.vue'
import Address from './Address.vue'
import Gallery from './Gallery.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import ServiceArea from './ServiceArea.vue'
import ServiceOffer from './ServiceOffer.vue'
import TransactionItem from './TransactionItem.vue'

export default {
  components: {
    BButton,
    BModal,
    BBadge,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormRating,
    BFormTextarea,
    BForm,
    BAlert,
    quillEditor,
    BAvatar,
    WorkingHours,
    ValidationProvider,
    ValidationObserver,
    License,
    Address,
    Gallery,
    ServiceArea,
    ServiceOffer,
    TransactionItem,

  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  props: {
    is_created: { type: Boolean, default: false },
  },
  data() {
    return {
      is_update: false,
      has_license: false,
      selectedTags: [],
      authorize_state: ['Virginia', 'District of Columbia', 'Maryland'],
      search_state: null,
      search_county: null,
      search_category: null,
      search_sub_category: null,
      sort_contact_type: 'desc',
      required,
      email,
      longitude: -78.024902,
      latitude: 37.926868,
      is_edited: false,
      map: null,
      map_address: '',
      fields: [{ key: 'id', label: '#ID' }, 'name', { key: 'state_id', label: 'State' }, { key: 'county_id', label: 'County' }, { key: 'category_id', label: 'Category' }, { key: 'status', label: 'Status' }],
      selected: null,
      isModalShow: false,
      selected_user: null,
      selected_tags: [],
      search_keyword: '',
      profile: {
        member_id: 1,
        name: '',
        phone: '',
        email: '',
        city: '',
        address: '',
        state_id: null,
        county_id: null,
        zip_code: '',
        lng: null,
        lat: null,
        license: '',
        website: '',
        category_id: 1,
        sub_category_id: null,
        // tags: [],
        contact_name: '',
        contact_title: '',
        contact_email: '',
        contact_phone: '',
        description: '',
        system_status: null,
        application_status: null,
      },
      system_status: {
        status: '',
        reason: '',
        status_type: 'system_status',
      },
      application_status: {
        status: '',
        reason: '',
        status_type: 'application_status',
      },
      tab_items: [
        { id: 1, title: 'Infomation' },
        { id: 3, title: 'Services Offered' },
        { id: 4, title: 'Description' },
        { id: 5, title: 'Business Hours' },

        { id: 7, title: 'Address' },
        { id: 8, title: 'Gallery' },
        { id: 9, title: 'Reviews' },
      ],
      is_tab_active: 1,
      system_options: [
        { value: 'active', text: 'active' },
        { value: 'cancel', text: 'cancel' },
      ],
      application_options: [
        { value: 'active', text: 'active' },
        { value: 'waiting for review', text: 'waiting for review' },
        { value: 'blocked by vendor', text: 'blocked by vendor' },
        { value: 'cancel', text: 'cancel' },
      ],
    }
  },
  computed: {
    business_profiles() {
      return this.$store.state.businessProfile.admin_business_profiles
    },
    profile_params() {
      return this.$store.state.businessProfile.admin_param
    },
    totalRows() {
      return this.$store.state.businessProfile.total_admin_count
    },
    profile_reviews() {
      return this.$store.state.review.business_profile_reviews
    },
    totalReviewRows() {
      return this.$store.state.review.total_reviews
    },
    hours: {
      get() {
        return this.$store.state.workingHours.hours
      },
      set(value) {
        return value
      },
    },
    socia_media: {
      get() {
        return this.$store.state.socialMedia.socia_media
      },
      set(value) {
        return value
      },
    },
    maps: {
      get() {
        return this.$store.state.businessProfile.maps
      },
      set(value) {
        return value
      },
    },
    gallery_items() {
      return this.$store.state.businessProfile.gallery_items
    },
    cover_photo() {
      return this.$store.state.businessProfile.cover_photo
    },
    address: {
      get() {
        return this.$store.state.businessProfile.address
      },
      set(value) {
        return value
      },
    },
    users() {
      const member = this.$store.state.user.members
      return member
    },
    user_options() {
      return this.users.map(item => {
        const data = { value: item.id, text: `${item.first_name} ${item.last_name}` }
        return data
      })
    },
    categories() {
      return this.$store.state.category.categories
    },
    categories_options() {
      return this.categories.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    // sub_categories_options() {
    //   return this.sub_categories.map(item => {
    //     const data = { value: item.id, text: item.name }
    //     return data
    //   })
    // },
    tags() {
      return this.$store.state.tag.tags
    },
    tag_options() {
      return this.tags.map(item => {
        const data = { title: item.name, id: item.id }
        return data
      })
    },
    states() {
      return this.$store.state.state.states
    },
    counties() {
      return this.$store.state.county.counties
    },
    transactions() {
      return this.$store.state.transaction.business_transaction
    },
    state_options() {
      return this.states.map(item => {
        const data = { value: item.id, text: `${item.name}` }
        return data
      })
    },
    county_options() {
      return this.counties.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    service_area: {
      get() {
        return this.$store.state.serviceArea.service_area
      },
      set(value) {
        return value
      },
    },
    service_offer: {
      get() {
        return this.$store.state.serviceOffer.service_offer
      },
      set(value) {
        return value
      },
    },
    licenses: {
      get() {
        return this.$store.state.license.licenses
      },
      set(value) {
        return value
      },
    },
    allCounties() {
      return this.$store.state.county.all_counties
    },
  },
  watch: {
    latitude(newValue) {
      if (this.map_enable === true) {
        this.map.setView([newValue, this.longitude], 13)
      }
    },
    longitude(newValue) {
      if (this.map_enable === true) {
        this.map.setView([this.latitude, newValue], 13)
      }
    },
    '$store.state.businessProfile.gallery_item': {
      handler(oldValue, newValue) {
        newValue.forEach(item => {
          this.profile.gallery.push(item)
        })
      },
      deep: true,
    },
    '$store.state.businessProfile.cover_photo': {
      handler() {
        if (this.is_update === true) {
          this.is_edited = true
        }
      },
      deep: true,
    },
    profile: {
      handler() {
        if (this.is_update === true) {
          this.is_edited = true
        }
      },
      deep: true,
    },
    '$store.state.businessProfile.address': {
      handler() {
        if (this.is_update === true) {
          this.is_edited = true
        }
      },
      deep: true,
    },
    '$store.state.businessProfile.business_profile_details': {
      handler(oldValue, newValue) {
        let details = null
        if (oldValue === null) return
        details = oldValue

        // Add 'Reviews' tab if not already present
        const tabs9 = this.tab_items.filter(tab => tab.id === 9)
        if (tabs9.length < 1) {
          this.tab_items.push({ id: 9, title: 'Reviews' })
        }

        // Add 'Transactions' tab if not already present
        const tabs10 = this.tab_items.filter(tab => tab.id === 10)
        if (tabs10.length < 1) {
          this.tab_items.push({ id: 10, title: 'Transactions' })
        }

        // Add 'Status Activities' tab if not already present
        const tabs11 = this.tab_items.filter(tab => tab.id === 11)
        if (tabs11.length < 1) {
          this.tab_items.push({ id: 11, title: 'Status Activities' })
        }

        if (details.authorized_states.length < 1) {
          const newTab = this.tab_items.filter(tab => tab.id !== 2)
          this.tab_items = newTab
        } else {
          const newTab = this.tab_items.filter(tab => tab.id === 2)
          if (newTab.length < 1) {
            this.tab_items.splice(1, 0, { id: 2, title: 'Service Area' })
          }
        }

        // Dispatch actions to retrieve business reviews, transactions, and profile cover photo
        this.$store.dispatch('review/getBusinessReviews', { page: 1, per_page: 20, business_profile_id: details.id })
        this.$store.dispatch('transaction/getTransactionByBusinessProfile', details.id)
        this.$store.dispatch('businessProfile/getProfileCoverPhoto', details.cover)

        // Update various properties based on the retrieved business profile details
        this.profile = details
        this.address.address1 = details.address1
        this.map_address = details.address1
        this.longitude = details.lng
        this.latitude = details.lat
        this.address.city = details.city
        this.address.state_id = details.state_id
        this.address.zip_code = details.zip_code
        this.profile.lng = details.lng
        this.profile.lat = details.lat
        this.profile.system_status = details.system_status
        this.profile.application_status = details.application_status
        this.application_status.status = details.application_status
        this.system_status.status = details.system_status
        this.application_status.reason = details.application_status_reason
        this.system_status.reason = details.system_status_reason

        // Update 'hours' array with business hours data
        details.business_hours.forEach(elm => this.hours.push({
          id: elm.id,
          day_id: elm.day_id,
          business_hours_start: elm.business_hours_start,
          business_hours_end: elm.business_hours_end,
        }))

        // Add 'License' tab and update 'licenses' array if state license is not required for the category
        const categoryItem = this.categories.filter(elm => elm.id === this.profile.category_id)
        if (categoryItem[0].state_license_required === false) {
          const tabs6 = this.tab_items.filter(tab => tab.id === 6)
          if (tabs6.length === 0) {
            const data = { id: 6, title: 'License' }
            this.tab_items.splice(1, 0, data)
          }
          details.licenses.forEach(elm => this.licenses.push({
            business_profile_id: elm.business_profile_id,
            id: elm.id,
            license: elm.license,
            license_expiration_date: elm.license_expiration_date,
            state: elm.state,
          }))
        }

        // Update 'service_offer' array with service names
        details.services.forEach(elm => this.service_offer.push({
          business_profile_id: elm.business_profile_id,
          id: elm.id,
          name: elm.service.name,
        }))

        // Update 'service_area' array with service areas data
        details.service_areas.forEach(elm => {
          const countyOptions = this.allCounties.filter(filterData => filterData.state_id === elm.state_id)
          this.service_area.unshift({
            business_profile_id: elm.business_profile_id,
            id: elm.id,
            state_id: elm.state_id,
            county_id: elm.county_id,
            state_options: details.authorized_states.map(stateItem => ({ value: stateItem.state.id, text: stateItem.state.name })),
            county_option: countyOptions.map(options => ({ value: options.id, text: options.name })),
            category_id: details.category_id,
            is_update: true,
          })
        })

        // Update 'address.county_id' and retrieve counties based on the state ID
        if (details.county_id !== undefined || details.county_id !== null) {
          this.address.county_id = details.county_id
          this.getCounty(details.state_id)
        }

        // Set 'has_license' to true if a license is present
        if (details.license !== '') {
          this.has_license = true
        }

        // Set 'is_update' and 'isModalShow' to true, show the modal, and reset 'is_edited' after a delay
        this.is_update = true
        this.isModalShow = true
        this.$bvModal.show('app-modal')
        const dom = this
        setTimeout(() => {
          dom.is_edited = false
        }, 100)
      },
      deep: false,
    },

  },
  mounted() {
    this.getUsers()
    this.getDays()
  },
  methods: {
    getDays() {
      return this.$store.dispatch('businessProfile/getDays')
    },
    getImage(image) {
      if (image !== null || image !== '') {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return image
    },
    getUserText(firstName, lastName) {
      const firstChar = firstName.charAt(0)
      const lastChar = lastName.charAt(0)
      return firstChar + lastChar
    },
    showFormContent(id) {
      this.is_tab_active = id
    },
    getUsers() {
      return this.$store.dispatch('user/getMembers', { page: 1, per_page: 10, user_type: 'member' })
    },
    changeCategory(item) {
      const categoryItem = this.categories.filter(elm => elm.id === item)
      if (categoryItem[0].state_license_required === false) {
        const tabs6 = this.tab_items.filter(tab => tab.id === 6)
        if (tabs6.length === 0) {
          const data = { id: 6, title: 'License' }
          this.tab_items.splice(1, 0, data)
        }
      }
    },
    // showModal() {
    //   this.$bvModal.show('app-modal')
    //   this.isModalShow = true
    //   this.tab_items = this.tab_items.filter(item => item.id !== 9)
    // },
    date(date) {
      return moment(date).format('ddd DD-MMM-YY')
    },
    hide(e) {
      if (this.is_edited === true) {
        e.preventDefault()
        this.$swal({
          title: 'Do you want to save changes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save Changes',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.is_edited = false
            this.updateBusinessProfile()
          } else if (result.dismiss === 'cancel') {
            this.is_edited = false
            this.$store.dispatch('businessProfile/emptyBusinessProfileDetails', null)
            this.$store.dispatch('socialMedia/emptySocialData')
            this.$store.dispatch('workingHours/emptyData')
            this.$store.dispatch('serviceArea/emptyServiceAreaData')
            this.$store.dispatch('serviceOffer/emptyServiceOfferData')
            this.$store.dispatch('businessProfile/emptyAddress')
            this.$store.dispatch('businessProfile/emptyGallery', [])
            this.$store.dispatch('businessProfile/emptyCoverPhoto', '')
            this.$store.dispatch('license/emptyLicenseData')
            this.$bvModal.hide('app-modal')
            this.isModalShow = false
            this.is_update = false
            this.emptyProfile()
            this.selectedTags = []
            this.map_address = ''
            this.is_tab_active = 1
            const newData = this.tab_items.filter(elm => elm.id !== 6)
            this.tab_items = newData
          }
        })
      } else {
        this.$store.dispatch('businessProfile/emptyBusinessProfileDetails', null)
        this.$store.dispatch('socialMedia/emptySocialData')
        this.$store.dispatch('workingHours/emptyData')
        this.$store.dispatch('serviceArea/emptyServiceAreaData')
        this.$store.dispatch('serviceOffer/emptyServiceOfferData')
        this.$store.dispatch('businessProfile/emptyAddress')
        this.$store.dispatch('businessProfile/emptyGallery', [])
        this.$store.dispatch('businessProfile/emptyCoverPhoto', '')
        this.$store.dispatch('license/emptyLicenseData')
        this.$bvModal.hide('app-modal')
        this.isModalShow = false
        this.is_edited = false
        this.is_update = false
        this.emptyProfile()
        this.selectedTags = []
        this.map_address = ''
        this.is_tab_active = 1
        const newData = this.tab_items.filter(elm => elm.id !== 6)
        this.tab_items = newData
      }
    },
    getCounty(id) {
      return this.$store.dispatch('county/getCounties', id)
    },
    changeHasLicense() {
      this.has_license = !this.has_license
    },
    createBusinessProfile() {
      this.$refs.profileForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          delete this.profile.address
          if (this.profile.sub_category_id === null) {
            delete this.profile.sub_category_id
          }
          const data = this.profile
          data.city = this.address.city
          data.address1 = this.address.address1
          data.state_id = this.address.state_id
          data.county_id = this.address.county_id
          data.zip_code = this.address.zip_code
          data.lng = Number(this.longitude)
          data.lat = Number(this.latitude)
          data.gallery_items = this.gallery_items
          data.business_hours = this.hours
          // data.social_handles = this.socia_media
          data.cover_photo = this.cover_photo
          data.services = this.service_offer
          const categoryItem = this.categories.filter(elm => elm.id === this.profile.category_id)
          if (categoryItem[0].state_license_required === false) {
            data.licenses = this.licenses
          }
          data.service_areas = this.service_area.map(item => ({ state_id: item.state_id, county_id: item.county_id }))
          // if (this.selectedTags.length > 0) {
          //   data.tags = this.selectedTags.map(item => item.id)
          // }
          this.$store.dispatch('businessProfile/createProfile', { item: data, param: this.profile_params })
          this.hide()
        }
      })
    },
    updateBusinessProfile() {
      this.$refs.profileForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          const data = this.profile
          data.city = this.address.city
          data.address1 = this.address.address1
          data.state_id = this.address.state_id
          data.county_id = this.address.county_id
          data.zip_code = this.address.zip_code
          data.lng = Number(this.profile.lng)
          data.lat = Number(this.profile.lat)
          data.cover_photo = this.cover_photo
          if (data.cover_photo.name === '') {
            delete data.cover_photo
          }
          delete data.gallery
          delete data.created_at
          delete data.sub_category_id
          delete data.gallery_items
          delete data.business_hours
          delete data.social_handles
          delete data.tags
          delete data.services
          delete data.service_areas
          delete data.licenses
          delete data.cover
          this.$store.dispatch('businessProfile/updateProfile', { item: data, param: this.profile_params })
          this.is_edited = false
          this.hide()
        }
      })
    },
    emptyProfile() {
      const dom = this
      setTimeout(() => {
        dom.profile = {
          member_id: null,
          name: '',
          phone: '',
          email: '',
          city: '',
          address: '',
          state_id: null,
          county_id: null,
          zip_code: '',
          lng: null,
          lat: null,
          license: '',
          website: '',
          category_id: 1,
          sub_category_id: null,
          tags: [],
          contact_name: '',
          contact_title: '',
          contact_email: '',
          contact_phone: '',
          description: '',
        }
      }, 200)
    },
    openSystemStatus() {
      this.$bvModal.show('system-status-modal')
    },
    openApplicationStatus() {
      this.$bvModal.show('application-status-modal')
    },
    changeSystemStatus() {
      this.$refs.systemStatusForm.validate().then(success => {
        if (success) {
          this.system_status.business_profile_id = this.profile.id
          this.$store.dispatch('businessProfile/changeApplicationStatus', { item: this.system_status, param: this.profile_params, user_type: 'manager' })
          this.$bvModal.hide('system-status-modal')
        }
      })
    },
    changeApplicationStatus() {
      this.$refs.applicationStatusForm.validate().then(success => {
        if (success) {
          this.application_status.business_profile_id = this.profile.id
          this.$store.dispatch('businessProfile/changeApplicationStatus', { item: this.application_status, param: this.profile_params, user_type: 'manager' })
          this.$bvModal.hide('application-status-modal')
        }
      })
    },
    openTransactionModel() {
      this.$bvModal.show('transaction-modal')
    },
    hideTransaction() {
      this.$bvModal.hide('transaction-modal')
    },
  },
}
</script>

<style scoped>

</style>
