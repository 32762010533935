<template>
  <div>
    <div class="add_tag_view multiple_item_view_box">
      <h4 class="mr-2">
        Area Served
      </h4>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-tooltip.hover.top="'Add new service area'"
        variant="outline-primary"
        @click="addInput"
      >
        <feather-icon icon="PlusIcon" />
      </b-button>
    </div>

    <div>
      <div class="row">
        <div
          v-for="(item, index) in service_area"
          :key="index"
          class="col-md-12 mb-2"
        >
          <div class="row">
            <div :class="update ? 'col-md-3' : 'col-md-3'">
              <b-form-group
                label="Category"
                label-for="h-category"
                label-cols-md="12"
              >

                <b-form-select
                  id="h-category"
                  v-model="item.category_id"
                  disabled
                  :options="categories_options"
                />

              </b-form-group>
            </div>
            <div :class="update ? 'col-md-3' : 'col-md-3'">
              <b-form-group
                label="State"
                label-for="h-state"
                label-cols-md="12"
              >
                <b-form-select
                  id="h-state"
                  v-model="item.state_id"
                  :options="item.state_options"
                  @change="filterState"
                />

              </b-form-group>
            </div>
            <div :class="item.is_update === false ? 'col-md-3' : 'col-md-4'">
              <b-form-group
                label="County"
                label-for="h-county"
                label-cols-md="12"
              >

                <b-form-select
                  id="h-category"
                  v-model="item.county_id"
                  :options="item.county_option"
                />

              </b-form-group>
            </div>
            <div
              class=" d-flex align-items-center"
              :class="update ? 'col-md-2' : 'col-md-2'"
              style="margin-top: 20px"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-b-tooltip.hover.top="'Delete'"
                variant="outline-danger"
                class="mt-0"
                @click="removeInput(index)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                v-if="item.is_update === false"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-b-tooltip.hover.top="'Save'"
                variant="outline-success"
                class="ml-2"
                @click="updateInput(index)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
            </div>
          </div>
          <!-- <div class="">
            <hr>
          </div> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BFormSelect, BFormGroup, BFormInput, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  props: {
    update: { type: Boolean, default: false },
    id: { type: Number, default: null },
    category: { type: Number, default: null },
    // eslint-disable-next-line vue/require-default-prop
    authorizedStates: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      user_type: '',
    }
  },
  computed: {
    profile_params() {
      return this.$store.state.businessProfile.admin_param
    },
    service_area: {
      get() {
        return this.$store.state.serviceArea.service_area
      },
      set(value) {
        return value
      },
    },
    states() {
      return this.$store.state.state.states
    },
    counties() {
      return this.$store.state.county.counties
    },
    state_options() {
      return this.states.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    userData() {
      return this.$store.state.auth.user
    },
    categories() {
      return this.$store.state.category.categories
    },
    categories_options() {
      return this.categories.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    // county_options() {
    //   return this.counties.map(item => {
    //     const data = { value: item.id, text: item.name }
    //     return data
    //   })
    // },
  },
  mounted() {
    this.user_type = localStorage.getItem('gts_user_type')
  },

  methods: {
    getBusinessProfile() {
      // Dispatch the appropriate action to retrieve the business profile based on the user type
      if (this.user_type === 'member') {
        this.$store.dispatch('businessProfile/getProfileVendor', this.profile_params)
      } else {
        this.$store.dispatch('businessProfile/getBusinessProfile', this.profile_params)
      }
    },

    addInput() {
      // Add a new object to the 'service_area' array with initial values
      this.service_area.push({
        state_id: null,
        county_id: null,
        county_option: [],
        state_options: this.authorizedStates.map(stateItem => ({ value: stateItem.state.id, text: stateItem.state.name })),
        category_id: this.category,
        is_update: false,
      })
    },

    removeInput(index) {
      // Remove the element at the specified index from the 'service_area' array
      const item = this.service_area[index]
      const dom = this

      if (item.id !== undefined) {
        const data = {
          business_profile_id: this.id,
          id: item.id,
          category_id: this.category,
          county_id: item.county_id,
          state_id: item.state_id,
        }
        // Delete the service area item from the store using the 'deleteSerivceArea' action
        this.$store.dispatch('serviceArea/deleteSerivceArea', data)
        setTimeout(() => {
          dom.getBusinessProfile()
        }, 300)
      }
      this.service_area.splice(index, 1)
    },

    updateInput(index) {
      // Update the element at the specified index in the 'service_area' array based on its properties
      const item = this.service_area[index]
      const dom = this
      // eslint-disable-next-line camelcase
      const category_name = this.categories_options.filter(cat => cat.value === this.category)[0]
      if (item.state_id !== null && item.county_id !== null) {
        let data
        if (item.id === undefined || item.id === null) {
          // delete item.county_option
          // delete item.is_update
          // delete item.state_options
          data = {
            business_profile_id: this.id,
            category_id: this.category,
            county_id: item.county_id,
            state_id: item.state_id,
            category_name: category_name.text
          }
          // Add the service area item to the store using the 'addSerivceArea' action
          this.$store.dispatch('serviceArea/addSerivceArea', data)
          setTimeout(() => {
            dom.getBusinessProfile()
          }, 300)
        } else {
          data = item
          data.business_profile_id = this.id
          // Update the service area item in the store using the 'updateSerivceArea' action
          this.$store.dispatch('serviceArea/updateSerivceArea', data)
          setTimeout(() => {
            dom.getBusinessProfile()
          }, 300)
        }
      } else {
        // Show a toast notification if form fields are not filled before saving
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Fill the form fields before you can save',
            icon: 'AlertTriangleIcon',
            text: '',
            variant: 'danger',
          },
        })
      }
    },

    filterState(id) {
      // Dispatch the 'getCounties' action to retrieve the counties based on the state ID
      this.$store.dispatch('county/getCounties', id)
      const dom = this
      setTimeout(() => {
        // Get the most recent service area item that matches the state ID and update its county options
        const data = dom.service_area.filter(item => item.state_id === id).reverse()[0]
        data.county_option = dom.counties.map(item => ({ value: item.id, text: item.name }))
      }, 300)
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
